/**
* Body smooth scroll behavior
*/
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;

}
:root {
	--index: calc(1vw + 1vh);
	--transition: 1.5s cubic-bezier(.05, .5, 0, 1);
  
}
html, body {
   
    scroll-behavior: smooth;
}

body {
    font-family: 'Outfit', sans-serif;
    background: #000000;
    font-family: 'Outfit', sans-serif;
}

.textarea-theme-two::-webkit-resizer {
    border: none;
    outline: none;
    background: #268DBB;
}

/**
* Custom scrollbar styles
*/
body::-webkit-scrollbar,
div::-webkit-scrollbar {
    width: 0.5em;
}

body::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
    background-color: none;
    background: none;
}

body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
    background-color: #4a4e67;
    border-radius: 10px;
    outline: none;
}

video {
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100vh;
    z-index: -1;
    background-size: cover;
    transition: 1s opacity;
}

.video-parallax {
    -webkit-transition-position: fixed;
    position: fixed;
}

.transition-lnd {
    transition: all 1s;
}

.fading-lnd {
    opacity: 0;
}

.preloader-animation {
    animation-name: preloader_animation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes preloader_animation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        display: none;
    }
}

/* Preloader animation class theme three */
.preloader-animation-theme-three_transition {
    animation-name: preloader_animation_t3_transition;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes preloader_animation_t3_transition {
    0% {
        top: -130%;
    }
    100% {
        top: 0%;
    }
}

.preloader-animation-theme-two_transition {
    animation-name: slideFromLeft;
    animation-duration: 1s;
    animation-fill-mode: forwards;
   
  }
  
  @keyframes slideFromLeft {
    from {
      left: -130%;
    }
    to {
     left: 0%;
    }
  }