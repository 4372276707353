@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
    body {
        font-family: 'Plus Jakarta Sans', sans-serif;
    }
}

input[type="text"]:focus ~ .input-text,
input[type="text"]:not(:placeholder-shown) ~ .input-text {
    @apply text-primary_one-50 transform -translate-y-7 -translate-x-6 scale-75;
}

textarea:focus ~ .input-text,
textarea:not(:placeholder-shown) ~ .input-text {
    @apply text-primary_one-50 transform -translate-y-7 -translate-x-6 scale-75;
}
