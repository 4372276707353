/**
* Body smooth scroll behavior
*/
html,
body {
    scroll-behavior: smooth;
}

/**
* Custom scrollbar styles
*/
body::-webkit-scrollbar,
textarea::-webkit-scrollbar,
div::-webkit-scrollbar {
    width: 0.5em;
}

body::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
    background-color: rgba(22, 23, 31, 1);
}

body::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
    background-color: #4a4e67;
    border-radius: 10px;
    outline: none;
}

.textarea-theme-three::-webkit-resizer {
    border: none;
    outline: none;
    background: #2C2E3E;
}

textarea::-webkit-scrollbar-track {
    border-radius: 10px;
}

.custom-bounce-animation {
    animation: bounce 2s infinite;
    transform-origin: center bottom;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(-10px);
    }
}

/* Theme one transition effect */

.button-click-transition-effect-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #40afde;
    z-index: 1;
}