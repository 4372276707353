@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	
}
:root {
	--index: calc(1vw + 1vh);
	--transition: 1.5s cubic-bezier(.05, .5, 0, 1);
}
body {
	background-color: black;
  font-family: 'Poppins', sans-serif;

}
.input-two, .textarea-two{
	box-shadow: none;
	border:none;
	outline: none;
	text-transform: none;
	font-weight: 500;
}

.page {
  display: none;
}

.page.active {
  display: block;
}
.scroll-container{
  scroll-behavior: smooth;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  height: 100vh;
}

.layers {
	perspective: 2400px;
	overflow: hidden;
  scroll-snap-align: start; 
  z-index: 10;
}
.layers__container {
	
	min-height: 100;
	transform-style: preserve-3d;
	transform: rotateX(var(--move-y)) rotateY(var(--move-x));
	will-change: transform;
	transition: transform var(--transition);
}

.layer-1 {
	transform: translateZ(-55px) scale(1.06);
}
.layer-2 {
	transform: translateZ(500px) scale(.6);
	
}
.layer-3 {
	transform: translateZ(180px) scale(.8);
}

.hero-content {
	text-align: center;
	text-transform: uppercase;
}
.hero-content span {
	display: block;
}

.button-start {
	font-family: 'Raleway';
	font-weight: 800;
	text-transform: uppercase;
	letter-spacing: normal;
	font-size: 18px;
	letter-spacing: 1.0px;
	padding: 24px 36px;
	background-color:#FF2323;
	color: #fff;
	border-radius: 5px;
	outline: none;
	cursor: pointer;
}

.button {
	cursor: pointer;
  background: transparent;
  border: none;
	font-family: 'Raleway';
	letter-spacing: 1.0px;
	font-weight: 800;
	text-transform: uppercase;
  position: relative;
  color: #ffffff;
  z-index: 1;
}

.button::after,
.button::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all .4s;
}

.button::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #FF2323;
  border-radius: 10px;
}

.button::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  /* background: #ffffff2d; */
  /* backdrop-filter: blur(5px); */
  border-radius: 50px;
}

.button:hover::before {
  transform: translate(5%, 10%);
  width: 110%;
  height: 110%;
}

.button:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.button:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}
.blur-effect{  
	backdrop-filter: blur(10px);
}

.projectbg{
  /* transition: all 0.6s ease; */
  overflow: hidden;
  z-index: 1;
}

.projectbg:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleY(1) scaleX(1.25);
  top: 100%;
  width: 140%;
  height: 180%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  transition: all 0.1s 0.1s cubic-bezier(0.1, 0, 0.1, 0.1);
  z-index: -1;
}

.projectbg:after {
  content: "";
  position: absolute;
  left: 55%;
  transform: translateX(-50%) scaleY(1) scaleX(1.45);
  top: 180%;
  width: 160%;
  height: 190%;
  background-color: #000000;
  border-radius: 50%;
  display: block;
  transition: all 0.1s 0.1s cubic-bezier(0.1, 0, 0.1, 0.1);
  z-index: -1;
}
.projectbg:hover:before {
  top: -35%;
  background-color: #00000042;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  fill: #ffffff;
}

.projectbg:hover:after {
  top: -45%;
  background-color: #00000075; 
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

::selection{
  background-color: #FF2323;
}
